<template>
  <div>
    <div class="pb-6 pt-[84px] px-6 w-full h-screen bg-[#F7F7FC]">
      <div class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-lg">
        <div class="flex flex-col justify-between h-full">
          <div class="flex flex-col flex-grow h-full border-[#ECEEF5]">
            <ReportsFilterV2></ReportsFilterV2>
            <ReportsTableV2></ReportsTableV2>
          </div>
          <PaginateReportsV2></PaginateReportsV2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {reportTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'

export default ({
  components: {
    ReportsFilterV2: () => import ('@/views/pages/reports/dialogs/ReportsFiltersV2.vue'),
    ReportsTableV2: () => import ('@/views/pages/reports/dialogs/ReportsTableV2.vue'),
    PaginateReportsV2: () => import ('@/views/pages/reports/dialogs/PaginateReportsV2.vue'),
  },
  data() {
    return {
      emailReports: [],
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      allBrands: [],
      allCampaign: []
    }
  },
  async created() {
    this.self = this
    await this.self.fetchReports()
  },
  computed: {
    ...mapGetters(['getReportsAdd', 'getReports', 'getReportsLoaders', 'getEmailReports'])
  },
  methods: {
    ...mapActions([
      'fetchReports',
      'deleteReport',
      'changeArchive',
      'fetchEmailReports',
      'dispatchManualReports',
      'fetchBrandList',
      'fetchCampaignsList'
    ]),
  }
})
</script>

